<template>
   <div :class='{"light":$root.d,"dark":!$root.d}'>
       <div class="row" :class='{"bg-light":$root.d,"bg-dark":!$root.d}'>
           <div class="col-12">
               <div class="row">
                   <div class="col-12 my-5">
                       <h3 class='text-center pg-text'>Sample Text</h3>
                       <img v-if='$root.d' src="@/assets/sample-one-light.jpg" alt="" class="d-block mx-auto">
                       <img v-else src="@/assets/sample-one.jpg" alt="" class="d-block mx-auto">
                   </div>
                   <div class="col-12 my-5">
                       <h3 class='text-center pg-text'>Sample Text</h3>
                       <img v-if='$root.d' src="@/assets/sample-two-light.jpg" alt="" class="d-block mx-auto">
                       <img v-else src="@/assets/sample-two.jpg" alt="" class="d-block mx-auto">
                   </div>
                   <div class="col-12 my-5">
                       <h3 class='text-center pg-text'>Sample Text</h3>
                       <img v-if='$root.d' src="@/assets/sample-three-light.jpg" alt="" class="d-block mx-auto">
                       <img v-else src="@/assets/sample-three.jpg" alt="" class="d-block mx-auto">
                   </div>
               </div>
           </div>
           
       </div>

            <div class="mx-width-extra">
                <Footer></Footer>

            </div>


        </div>
</template>

<script>
    // import WhitePaperComp from '../components/WhitePaperComp.vue'
    // import SmallCard from '../components/SmallCard.vue'
    // import Footer from '../components/Footer.vue'
    const Footer = () => import("../components/Footer.vue");
    const SmallCard = () => import("../components/SmallCard.vue");
    const WhitePaperComp = () => import("../components/WhitePaperComp.vue");

export default {
    components:{Footer,SmallCard,WhitePaperComp},
    data() {
        return {
            elements:[
                {
                    id:1,
                    text:"Sample Text",
                    img:"../assets/sample-one.jpg"
                },
                {
                    id:2,
                    text:"Sample Text",
                    img:"../assets/sample-two.jpg"
                },
                {
                    id:2,
                    text:"Sample Text",
                    img:"../assets/sample-three.jpg"
                },
            ],
            elementsDark:[
                {
                    id:1,
                    text:"Sample Text",
                    img:"../assets/sample-one-light.jpg"
                },
                {
                    id:2,
                    text:"Sample Text",
                    img:"../assets/sample-two-light.jpg"
                },
                {
                    id:2,
                    text:"Sample Text",
                    img:"../assets/sample-three-light.jpg"
                },
            ],
        }
    },
    methods:{
    }
}
</script>

<style scoped>

</style>